import React, { useEffect, useState } from "react"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import MetaDetails from "../components/seoComponents/MetaDetails"
import { Breadcrumbs } from "../components/layout/Breadcrumb"
import styled from "styled-components"
import { device } from "../utils/mediaQueries"
import BlogFeaturedImage from "../components/images/BlogFeaturedImage"
import Streaming from "../assets/brand.svg"
import Browser from "../assets/www.svg"
import Music from "../assets/play-button.svg"
import Gaming from "../assets/joystick.svg"
import VideoCall from "../assets/headset.svg"
import Download from "../assets/files-and-folders.svg"

const BlogPost = styled.div`
  padding: 0rem 9rem;
  background-color: white;
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  @media ${device.tablet} {
    padding: 0.2rem;
  }
`
const TocHeader = styled.h4`
  text-align: center;
  color: black;
`

const ToC = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  position: ${props => (props.fixed ? "fixed" : "relative")};
  top: 0px;
  font-size: 1rem;
  font-weight: 300;
  @media ${device.tablet} {
    margin: 1rem;
    position: relative;
    padding: 1rem 2rem;
    border: 1px solid #dbdbdb;
  }
`

const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 8;
`

const LeftSideBar = styled.div`
  flex: 2;
  margin: 0px auto;
`
const RightSideBar = styled.div`
  flex: 1;
`

const TableLink = styled.a`
  padding: 0.2rem;
  padding-left: ${props => props.margin || "1px"};
  text-decoration: none;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.4rem;

  margin-bottom: 0.7rem;
`

const Container = styled.div`
  padding: 2rem 6rem;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  background-color: white;
  @media ${device.tablet} {
    flex-direction: column-reverse;
    margin: 0px;
    padding: 1rem;
    border-radius: 0px;
  }
`

const HeadingContainer = styled.div`
  margin: 0rem;
`

const FeaturedImageConainer = styled.div`
  margin: 1rem auto;
`
const FeaturedImage = styled(BlogFeaturedImage)`
  margin: 1rem auto;
`

const SpeedTest = styled.a`
  text-decoration: none;
  background-color: #a35dbc;
  padding: 6rem;
  border-radius: 800px;
  margin: 1rem auto;
  font-size: 2rem;
  color: white;
  :hover {
    background-color: pink;
  }
`
const SpeedTestHeading = styled.h3`
  font-weight: 200;
  font-size: 1.5rem;
  text-align: center;
  color: black;
`

const BodyContainer = styled.div`
  background-color: white;
`

const SpeedCategories = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Heading3 = styled.h3`
  color: black;
`

const SpeedCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  width: 300px;
`

const SpeedTitle = styled.h5`
  font-size: 1.4rem;
  color: white;
  font-weight: 600;
`

const SpeedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SpeedItem = styled.p`
  color: white;
  text-align: center;
`

const SpeedItemLower = styled.p`
  color: black;
  text-align: center;
`

const UppedSide = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  background-color: #518084;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`
const LowerSide = styled.div`
  display: flex;
  background-color: #f2f2f2;
  font-weight: 800;
  font-size: 1rem;
  width: 90%;
  padding: 0.2rem 1rem;
  flex: 1;
  flex-direction: column;
`

const IconContainer = styled.div`
  height: 50px;
  width: 50px;
  background-color: white;
  padding: 2rem;
  fill: #bc5d9d;
  border-radius: 100%;
`

const ContentContainer = styled.div`
  margin: 6rem 0rem;
  text-align: center;
`

const Template = ({ data }) => {
  const [position, setPosition] = useState(false)

  const listenScrollEvent = e => {
    if (window.scrollY > 390) {
      setPosition(true)
    }
    if (window.scrollY < 390) {
      setPosition(false)
    }
  }

  useEffect(() => {
    // Update the document title using the browser API
    window.addEventListener("scroll", listenScrollEvent)
  })

  return (
    <GlobalStyles>
      <MetaDetails
        title={data.markdownRemark.frontmatter.metaTitle}
        desc={data.markdownRemark.frontmatter.metaDesc}
      />
      <BodyContainer>
        <HeadingContainer>
          <Header1 color={"#a35dbc"} blog={"8rem"}>
            {data.markdownRemark.frontmatter.title}
          </Header1>
        </HeadingContainer>
        <Container>
          <LeftSideBar>
            <ToC fixed={position}>
              <TocHeader>Sisällysluettelo</TocHeader>
              {data.markdownRemark.headings.map((h, i) => (
                <TableLink
                  key={i}
                  href={`#${h.value
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .replace("?", "")}`}
                >
                  {i}. {h.value}
                </TableLink>
              ))}
            </ToC>
          </LeftSideBar>
          <MainArea>
            <SpeedTestHeading>
              Testaa kuinka nopeasti nettisi toimii
            </SpeedTestHeading>
            <SpeedTest
              href="https://halvinliittymaa.speedtestcustom.com/"
              target="_blank"
            >
              Aloita
            </SpeedTest>

            <ContentContainer>
              <Heading3>Mikä nopeus sopii mihinkin aktiviteettiin?</Heading3>
              <SpeedCategories>
                <SpeedCard>
                  <UppedSide>
                    <SpeedTitle>Videoiden katselu</SpeedTitle>
                    <IconContainer>
                      <Streaming />
                    </IconContainer>
                    <SpeedItem>Netflix, Viaplay, Ruutu</SpeedItem>
                  </UppedSide>
                  <LowerSide>
                    <SpeedContainer>
                      <SpeedItemLower>SD laatu</SpeedItemLower>
                      <SpeedItemLower>3Mbps</SpeedItemLower>
                    </SpeedContainer>
                    <SpeedContainer>
                      <SpeedItemLower>HD laatu</SpeedItemLower>
                      <SpeedItemLower>5Mbps</SpeedItemLower>
                    </SpeedContainer>
                    <SpeedContainer>
                      <SpeedItemLower>4K laatu</SpeedItemLower>
                      <SpeedItemLower>15Mbps</SpeedItemLower>
                    </SpeedContainer>
                  </LowerSide>
                </SpeedCard>

                <SpeedCard>
                  <UppedSide>
                    <SpeedTitle>Musiikin kuuntelu</SpeedTitle>
                    <IconContainer>
                      <Music />
                    </IconContainer>
                    <SpeedItem>Spotify</SpeedItem>
                  </UppedSide>
                  <LowerSide>
                    <SpeedContainer>
                      <SpeedItemLower>HD laatu</SpeedItemLower>
                      <SpeedItemLower>5Mbps</SpeedItemLower>
                    </SpeedContainer>
                  </LowerSide>
                </SpeedCard>

                <SpeedCard>
                  <UppedSide>
                    <SpeedTitle>Online pelaaminen</SpeedTitle>
                    <IconContainer>
                      <Gaming />
                    </IconContainer>
                    <SpeedItem>PC, PS, XBOX</SpeedItem>
                  </UppedSide>
                  <LowerSide>
                    <SpeedItemLower>2-20Mbps</SpeedItemLower>
                  </LowerSide>
                </SpeedCard>

                <SpeedCard>
                  <UppedSide>
                    <SpeedTitle>Videopuhelut</SpeedTitle>
                    <IconContainer>
                      <VideoCall />
                    </IconContainer>
                    <SpeedItem>Skype, Zoom, Facetime</SpeedItem>
                  </UppedSide>
                  <LowerSide>
                    <SpeedContainer>
                      <SpeedItemLower>Normaali laatu</SpeedItemLower>
                      <SpeedItemLower>1Mbps</SpeedItemLower>
                    </SpeedContainer>
                    <SpeedContainer>
                      <SpeedItemLower>HD laatu</SpeedItemLower>
                      <SpeedItemLower>5Mbps</SpeedItemLower>
                    </SpeedContainer>
                  </LowerSide>
                </SpeedCard>

                <SpeedCard>
                  <UppedSide>
                    <SpeedTitle>Tiedostojen lataaminen</SpeedTitle>
                    <IconContainer>
                      <Download />
                    </IconContainer>
                    <SpeedItem>Pelit, ohjelmat</SpeedItem>
                  </UppedSide>
                  <LowerSide>
                    <SpeedContainer>
                      <SpeedItemLower>Hidas lataus</SpeedItemLower>
                      <SpeedItemLower>5Mbps</SpeedItemLower>
                    </SpeedContainer>
                    <SpeedContainer>
                      <SpeedItemLower>Keskinopea lataus</SpeedItemLower>
                      <SpeedItemLower>50Mbps</SpeedItemLower>
                    </SpeedContainer>
                    <SpeedContainer>
                      <SpeedItemLower>Nopea lataus</SpeedItemLower>
                      <SpeedItemLower>100Mbps</SpeedItemLower>
                    </SpeedContainer>
                  </LowerSide>
                </SpeedCard>

                <SpeedCard>
                  <UppedSide>
                    <SpeedTitle>Normaali selailu</SpeedTitle>
                    <IconContainer>
                      <Browser />
                    </IconContainer>
                    <SpeedItem>Iltasanomat, sähköposti, Facebook</SpeedItem>
                  </UppedSide>
                  <LowerSide>
                    <SpeedContainer>
                      <SpeedItemLower>Siedettävä latausaika</SpeedItemLower>
                      <SpeedItemLower>1Mbps</SpeedItemLower>
                    </SpeedContainer>
                    <SpeedContainer>
                      <SpeedItemLower>Nopea latausaika</SpeedItemLower>
                      <SpeedItemLower>5Mbps</SpeedItemLower>
                    </SpeedContainer>
                  </LowerSide>
                </SpeedCard>
              </SpeedCategories>
            </ContentContainer>
            <BlogPost
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            ></BlogPost>
          </MainArea>
          <RightSideBar></RightSideBar>
        </Container>
      </BodyContainer>
    </GlobalStyles>
  )
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        featuredImage
        path
        title
        metaDesc
        metaTitle
      }
      headings {
        depth
        value
      }
      timeToRead
    }
  }
`

export default Template
